.app {}

/* navabar css  #14D5FF*/

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  background: url("https://d19m59y37dris4.cloudfront.net/app/2-0/img/banner-4.86a86274.png");
  background-size: cover;
  background-repeat: no-repeat;

}

.nav-logo {
  width: 5rem;
  margin-top: 60px;
  padding: 10px 10px 10px 10px;
}



.container {
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.navbar {
  height: 60px;
  position: relative;
  width: 100%;
  top: 10px;
}

.logo {}

.menu-icon {
  display: none;
}

.nav-elements ul {
  display: flex;
  list-style-type: none;
}

.nav-elements ul li:not(:last-child) {
  margin-right: 60px;
}

.nav-elements ul a {
  font-size: 16px;
  font-weight: 400;
  color: #2f234f;
  text-decoration: none;
}

.nav-elements ul a.active {
  color: #574c4c;
  font-weight: 500;
  position: relative;
}

.nav-elements ul a.active::after {
  content: "";
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #def4fa;
}

@media (max-width: 768px) {
  .nav-elements ul li:not(:last-child) {
    margin-right: 30px;
  }
}

@media (max-width: 600px) {
  .menu-icon {
    display: block;
    cursor: pointer;
  }
  .nav-logo {
    width: 3rem;
    margin-top: 10px;
    padding: 10px 10px 10px 10px;
  }
  
  .nav-elements {
    position: absolute;
    right: 0;
    top: 60px;
    background-color: #ffff;
    width: 0px;
    transition: transform 0.3s ease-in-out;
    overflow: hidden;
  }

  .nav-elements.active {
    width: 100%;
    height: auto;
    transform: translateY(0);
  }

  .nav-elements ul {
    display: flex;
    flex-direction: column;
  }

  .nav-elements ul li {
    margin-right: unset;
    margin-top: 22px;
  }
}

/*content css*/

.content {
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;

}

.left {
  flex: 3;

  .left-content {
    display: flex;
    flex-wrap: wrap;
    width: fit-content;
    padding: auto;
    margin-right: 10rem;

    & h1 {
      font-size: 2rem;
    }

    & p {
      font-size: 1rem;
      /* font-size: calc(1.275rem + 0.3vw); */
    }

    .left-buttons {
      display: flex;
      flex-wrap: wrap;
      width: fit-content;
      padding: auto;


    }

  }

}

.right {
  flex: 1;
  width: 50%;
  padding: 10px;

}

.button {
  margin: 10px;
  background-color: #5954cb;
  text-decoration: none;
  color: white;
  font-weight: 400;
  padding: 10px;
  border-radius: 5px;
  border: none;
  cursor: pointer;

  .icon {
    margin-right: 10px;
    align-items: center;
  }

  &:hover {
    background-color: #574c4c;
  }


}

.right-img img {
  margin-top: 20px;
  width: 100%;
}

@media (max-width: 768px) {
  .content {
    flex-direction: column;
  }

  .left,
  .right {
    width: 100%;

    .left-content {
      display: flex;
      flex-wrap: wrap;
      width: fit-content;
      padding: auto;
      margin-right: 10rem;

      & h1 {
        font-size: 1.5rem;
      }

      & p {
        font-size: .8rem;
        /* font-size: calc(1.275rem + 0.3vw); */
      }

      .left-buttons {
        display: flex;
        width: fit-content;
        padding: auto;


      }

    }
  }

  h1 {
    font-size: 10rem;
  }

  .right-img img {
    margin-top: 20px;
    width: 20rem;
  }

}


/*footer css*/

.footer-clean {
  display: flex;
  justify-content: center;
  color: #4b4c4d;
  font-size: 0.5rem;

  t &.copyright {
    display: flex;
    justify-content: center;
  }
}